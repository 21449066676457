import React from 'react'
import { Box, Container, Grid, MenuItem, TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import Text from './Text'
import { useMobile } from '../utils/hooks';

// const useStyles = makeStyles(theme => ({
//     innerWrapper: {
//         boxShadow: `inset 0 0 0 3px rgba(255, 255, 255, .3)`
//     }
// }))


const Contact = ({ renderSubmit, bgComponent: BgComponent, formName }) => {
    const mobile = useMobile()

    return (
        <Box id="contact" overflow="hidden" position="relative">
            <BgComponent />

            <Box position="relative" zIndex={5}>
                <Container maxWidth="md" data-sal="fade"
                    data-sal-duration="1000" data-sal-delay="600">
                    <form name={formName} method="POST" action="/confirm" data-netlify="true" netlify-honeypot="bot-field">
                        <input type="hidden" name="form-name" value={formName} />
                        <Box px={mobile ? 0 : 4} py={mobile ? 3 : 6}>
                            <Text variant="h2" textAlign="center" mb={1}>
                                Apprendre les détails de l'Incubit Restart<br /><br />
                            </Text>

                            {/* <Text variant="h4" color="textSecondary" mb={mobile ? 3 : 6} textAlign="center">
                                invest in innovative projects from all over Europe
                        </Text> */}

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth variant="outlined" label="Votre nom" placeholder=" " required
                                        name={"name"} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth variant="outlined" label="Email" placeholder=" " name={"email"} required />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField fullWidth select variant="outlined" label="Vos expériences d'investissement" required
                                        name={"experiences"}>
                                        <MenuItem value={'Investisseur débutant'}>Investisseur débutant</MenuItem>
                                        <MenuItem value={'Investisseur professionnel'}>Investisseur professionnel</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField fullWidth select variant="outlined"
                                        label="Dans quels projets aimeriez-vous investir?" placeholder=" " required
                                        name={'projects'}>
                                        <MenuItem value={'Café'}>Café</MenuItem>
                                        <MenuItem value={'Restaurant'}>Restaurant</MenuItem>
                                        <MenuItem value={'Brasserie'}>Brasserie</MenuItem>
                                        <MenuItem value={'Coiffeur'}>Coiffeur</MenuItem>
                                        <MenuItem value={'Salon de beauté'}>Salon de beauté</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center">
                                        <FormControlLabel
                                            control={<Checkbox color="primary" value="true" name="newsletterConfirm" />}
                                            label="J'accepte de recevoir une newsletter d'Incubit."
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box mt={mobile ? 3 : 4} textAlign="center">
                                {renderSubmit()}
                            </Box>
                        </Box>
                    </form>
                </Container>
            </Box>
        </Box>

    )
}

export default Contact
