import React from 'react'
import { Grid, Box } from '@material-ui/core'
import Text from './Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ScrollToSectionContext } from './Layout'

const buisnesses = [
    { label: 'Café', icon: 'coffee' },
    { label: 'Restaurant', icon: 'utensils' },
    { label: 'Brasserie', icon: 'wine-glass' },
    { label: 'Coiffeur', icon: 'cut' },
    { label: 'Salon de beauté', icon: 'grin-hearts' },
]


const BuisnessItem = ({ label, icon, iconColor, }) => <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
>
    <Box mb={2}>
        <FontAwesomeIcon icon={icon} color={iconColor} size="3x" />
    </Box>
    
    <Text variant="h4" component="span" style={{opacity: .6}}>{label}</Text>
</Box>

const CardWithOverhangButton = ({ title, titleColor, paragraph, items, buttonSlot, className, iconColor }) => {
    const scrollToSection = React.useContext(ScrollToSectionContext)

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={className} p={7} pb={0}>
            <Text className="tlt" variant="h1" color={titleColor} textAlign="center">
                {title}
            </Text>

            <Box my={6}>
                <Grid container justify="center" spacing={8}>
                    {items.map(x => <Grid item key={x.label}>
                        <BuisnessItem {...x} iconColor={iconColor}/>
                    </Grid>)}
                </Grid>
            </Box>

            <Box mb={4}>
                <Text className="tlt" variant="h4" component="p" textAlign="center" fontWeight={400}>
                    {paragraph}
                </Text>
            </Box>

            <Box position="relative" style={{ transform: 'translateY(50%)' }} onClick={scrollToSection('contact')}>
                {buttonSlot}
            </Box>
        </Box>
    )
}

CardWithOverhangButton.defaultProps = {
    titleColor: "inherit",
    items: buisnesses
}

export default CardWithOverhangButton
